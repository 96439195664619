<template>
  <a-modal
    :title="form.id ? '编辑元素分组' : '新增元素分组'"
    v-model="visible"
    width="450px"
    :confirmLoading="saveLoading"
    @ok="save"
    @cancel="onClose"
    :maskClosable="false"
  >
    <a-form-model
      ref="form"
      :model="form"
      :rules="elementGroupEditionRules"
      :label-col="{ span: 5 }"
      :wrapper-col="{ span: 18 }"
    >
      <a-form-model-item label="元素类型" prop="cateType">
        <a-select
          v-model="form.cateType"
          placeholder="请选择元素类型"
          style="width: 100%"
          allowClear
          @change="changeCateType"
        >
          <a-select-option v-for="item in tabTypeList" :key="item.value" :value="item.value">{{
            item.label
          }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="模版类型" prop="tempType" v-if="form.cateType === 1">
        <a-select v-model="form.tempType" placeholder="请选择模版类型" style="width: 100%" allowClear>
          <a-select-option v-for="item in templateTypeList" :key="item.value" :value="item.value">{{
            item.label
          }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="分组" prop="cateName">
        <a-input v-model="form.cateName" placeholder="请输入分组名称"></a-input>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import BrandSelectInput from '@/components/xhsAgencyTool/BrandSelectInput';
import CarSeriesSelectInput from '@/components/xhsAgencyTool/CarSeriesSelectInput';
import { tabTypeList, templateTypeList } from '@/constant/xhs.js';
import { elementGroupEditionRules } from '../data';
import { addElementGroupApi, updateElementGroupApi } from '@/api/element.js';
import _ from 'lodash';

export default {
  components: { BrandSelectInput, CarSeriesSelectInput },
  data() {
    return {
      tabTypeList,
      templateTypeList,
      elementGroupEditionRules,
      visible: false,
      saveLoading: false,
      form: {
        id: undefined,
        cateName: '',
        cateType: undefined,
        pid: undefined,
        tempType: undefined,
      },
    };
  },
  computed: {
    principalIds() {
      return this.form.principalData ? [this.form.principalData] : [];
    },
  },
  methods: {
    openModal(form) {
      Object.assign(this.$data, this.$options.data());
      if (form)
        this.form = _.pick(_.cloneDeep({ ...form, tempType: form.tempType || undefined }), Object.keys(this.form));
      this.visible = true;
    },

    onClose() {
      this.visible = false;
    },

    changeCateType() {
      if (this.form.cateType !== 1) {
        this.form.tempType = undefined;
      }
    },

    async save() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          const saveApi = this.form.id ? updateElementGroupApi : addElementGroupApi;
          this.saveLoading = true;
          try {
            const { code, message } = await saveApi({ ...this.form, tempType: this.form.tempType || 0 });
            this.saveLoading = false;
            if (code === 200) {
              this.$message.success('操作成功');
              this.$emit('success');
              this.onClose();
            } else {
              this.$message.error(message);
            }
          } catch (error) {
            this.saveLoading = false;
          }
        }
      });
    },
  },
  created() {},
};
</script>

<style scoped lang="scss"></style>
