<template>
  <div>
    <a-row :gutter="[20, 10]" style="margin-bottom: 20px">
      <a-col :span="5">
        <a-select
          v-model="form.type"
          placeholder="请选择元素类型"
          style="width: 100%"
          allowClear
          @change="getDataList(true)"
        >
          <a-select-option v-for="item in tabTypeList" :key="item.value" :value="item.value">{{
            item.label
          }}</a-select-option>
        </a-select>
      </a-col>
      <a-col :offset="15" :span="4" style="text-align: right">
        <a-button type="primary" @click="addElementGroup">添加</a-button>
      </a-col>
    </a-row>
    <a-table
      :columns="columns"
      row-key="id"
      :pagination="false"
      :loading="loading"
      :data-source="elementGroupList"
      @change="onTableChange"
    >
      <a slot="name" slot-scope="text">{{ text }}</a>
      <div slot="operation" slot-scope="text, record">
        <a @click="updateElementGroup(record)">编辑</a>
        <a-divider type="vertical" />
        <a @click="deleteElementGroup(record.id)">删除</a>
      </div>
    </a-table>
    <base-pagination
      :currentPage="pagination.page"
      :options="['10', '30', '50']"
      :pageSize="pagination.pageSize"
      :total="pagination.total"
      @onChange="handlePaginationChange"
      @onShowSizeChange="handlePaginationChange"
    />

    <ElementGroupEdition ref="ElementGroupEdition" @success="getDataList()" />
  </div>
</template>

<script>
import { getElementGroupListApi, deleteElementGroupApi } from '@/api/element.js';
import ElementGroupEdition from './components/ElementGroupEdition.vue';
import { tabTypeList } from '@/constant/xhs.js';
import { columns } from './data';

export default {
  components: { ElementGroupEdition },
  data() {
    return {
      columns,
      tabTypeList,
      pagination: {
        page: 1,
        pageSize: 10,
        total: 1,
      },
      form: {
        type: undefined,
        sort: ['mtime,desc'],
      },
      loading: false,
      elementGroupList: [],
      sortMap: { mtime: 'descend' },
    };
  },
  methods: {
    async getDataList(isFirst) {
      if (isFirst) {
        this.pagination.page = 1;
      }
      this.loading = true;
      try {
        const { code, data, message } = await getElementGroupListApi({
          ...this.form,
          page: this.pagination.page,
          pageSize: this.pagination.pageSize,
        });
        this.loading = false;
        if (code === 200) {
          this.elementGroupList = data.list;
          this.pagination.total = data.total;
        } else {
          this.$message.error(message);
        }
      } catch (error) {
        console.log('error:', error);
        this.loading = false;
      }
    },
    /**
     * 翻页
     * @param {*} page
     * @param {*} pageSize
     */
    handlePaginationChange(page, pageSize) {
      this.pagination.page = page;
      this.pagination.pageSize = pageSize;
      this.getDataList();
    },
    onTableChange(pagination, filters, sorter) {
      console.log('sorter:', sorter);
      if (sorter.order) {
        this.sortMap[`${sorter.field}`] = `${sorter.field},${sorter.order === 'ascend' ? 'asc' : 'desc'}`;
      } else {
        delete this.sortMap[`${sorter.field}`];
      }
      this.form.sort = Object.keys(this.sortMap).map((key) => this.sortMap[key]);
      this.getDataList();
    },
    addElementGroup() {
      this.$refs.ElementGroupEdition.openModal();
    },
    updateElementGroup(record) {
      this.$refs.ElementGroupEdition.openModal(record);
    },
    deleteElementGroup(id) {
      this.$confirm({
        content: '确认删除该分组吗？',
        okText: '确定',
        cancelText: '取消',
        onOk: async () => {
          try {
            const { code, message } = await deleteElementGroupApi({
              id,
            });
            this.loading = false;
            if (code === 200) {
              this.$message.success('删除成功');
              this.getDataList();
            } else {
              this.$message.error(message);
            }
          } catch (error) {
            console.log('error:', error);
            this.loading = false;
          }
        },
      });
    },
  },
  mounted() {
    this.getDataList(true);
  },
};
</script>

<style scoped lang="scss"></style>
