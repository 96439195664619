export const templateTypeList = [
  {
    label: '模版',
    value: 1,
  },
  {
    label: '文转图',
    value: 2,
  },
  {
    label: '拼图',
    value: 3,
  },
];

export const templateTypeMap = templateTypeList.reduce((prev, next) => {
  prev[next.value] = next.label;
  return prev;
}, {});

export const tabTypeList = [
  { label: '模板', value: 1 },
  { label: '贴纸', value: 2 },
  { label: '文字', value: 3 },
  { label: '背景', value: 4 },
  { label: '字体', value: 5 },
  { label: '滤镜', value: 6 },
];

export const tabTypeMap = tabTypeList.reduce((prev, next) => {
  prev[next.value] = next.label;
  return prev;
}, {});
