import { tabTypeMap, templateTypeMap } from '@/constant/xhs.js';

export const columns = [
  {
    title: '元素类型',
    customRender(record) {
      return (
        (tabTypeMap[record.cateType] || '-') +
        (templateTypeMap[record.tempType] ? `-${templateTypeMap[record.tempType]}` : '')
      );
    },
  },
  {
    title: '分组',
    dataIndex: 'cateName',
    customRender(record) {
      return record || '-';
    },
  },
  {
    title: '操作人',
    customRender(record) {
      return record.modifier || '-';
    },
  },
  {
    title: '更新时间',
    dataIndex: 'mtime',
    sorter: true,
    defaultSortOrder: 'descend',
  },
  {
    align: 'center',
    title: '操作',
    width: 120,
    fixed: 'right',
    scopedSlots: { customRender: 'operation' },
  },
];

export const elementGroupEditionRules = {
  cateType: [{ required: true, message: '元素类型不能为空', trigger: 'change' }],
  tempType: [{ required: true, message: '模版类型不能为空', trigger: 'change' }],
  cateName: [{ required: true, message: '分组名称不能为空', trigger: 'blur' }],
};
